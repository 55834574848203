import * as React from "react"
import { ButtonStyles } from "./ButtonStyles"

const Button = ({ text, to, as }) => {
  return (
    <ButtonStyles className="button" type="submit" as={as} to={to}>
      <span>{text}</span>
    </ButtonStyles>
  )
}

export default Button
