import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import Listen from "../components/Listen/Listen"
import Watch from "../components/Watch/Watch"
import Contact from "../components/Contact/Contact"

const Index = () => {
  return (
    <>
      <Seo title="Punk Rock from Southsea, UK" />
      <Layout>
        <BannerModule />
        <Listen />
        <Watch />
        <Contact />
      </Layout>
    </>
  )
}

export default Index
