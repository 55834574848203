import styled from "styled-components"

export const WatchStyles = styled.section`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  
  .watch-videos {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    li {
      flex: 1 0 auto;
      position: relative;
      overflow: hidden;
      margin-bottom: var(--borderSpacing-small);
      //width: 100%;
      
      &:after {
        display: block;
        content: "";
        padding-top: 56.25%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`
