import * as React from "react"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"
import { ListenStyles } from "./ListenStyles"
import { StaticImage } from "gatsby-plugin-image"

const Listen = () => {
  const siteMeta = UseSiteMetadata()

  return (
    <ListenStyles>
      <div className={"container"}>
        <h2 className={"visually-hidden"}>Listen to us</h2>

        <div className={"listen-embed"}>
          {/*<iframe style={{borderRadius: '12px'}}*/}
          {/*        src="https://open.spotify.com/embed/album/1dJKt6aA9afx0UL7oYUoOc?utm_source=generator&theme=0"*/}
          {/*        width="100%" height="80" frameBorder="0" allowFullScreen=""*/}
          {/*        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>*/}

          <iframe style={{ borderRadius: "12px" }}
                  title={"Listen to Capsized on Spotify"}
                  src="https://open.spotify.com/embed/album/1dJKt6aA9afx0UL7oYUoOc?utm_source=generator" width="100%"
                  height="180px" frameBorder="0" allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        </div>

        <ul className={"listen-links"}>
          {siteMeta.spotifyUrl && (
            <li>
              <a
                href={`${siteMeta.spotifyUrl}`}
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <StaticImage src={"../../../static/logos/spotify.png"} placeholder="none" alt={"Spotify"} />
              </a>
            </li>
          )}
          {siteMeta.appleMusicUrl && (
            <li>
              <a
                href={`${siteMeta.appleMusicUrl}`}
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <StaticImage src={"../../../static/logos/apple-music.png"} placeholder="none" alt={"Apple Music"} />
              </a>
            </li>
          )}
          {siteMeta.youTubeUrl && (
            <li>
              <a
                href={`${siteMeta.youTubeUrl}`}
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                <StaticImage src={"../../../static/logos/youtube.png"} placeholder="none" alt={"YouTube"} />
              </a>
            </li>
          )}
        </ul>
      </div>
    </ListenStyles>
  )
}

export default Listen