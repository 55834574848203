import * as React from "react"
import { WatchStyles } from "./WatchStyles"

const Watch = () => {

  return (
    <WatchStyles className="section">
      <div className={"container"}>
        <h2>Watch</h2>

        <ul className={"watch-videos"}>
          <li>
            <iframe src="https://www.youtube-nocookie.com/embed/dIFjqzBwYiU"
                    title="YouTube video player - No Excuse by Capsized"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </li>
          <li>
            <iframe src="https://www.youtube-nocookie.com/embed/14g86a-d94w"
                    title="YouTube video player - See How Much I Care by Capsized"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          </li>
        </ul>
      </div>
    </WatchStyles>
  )
}

export default Watch