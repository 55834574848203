import styled from "styled-components"

export const ButtonStyles = styled.button`
  background-color: var(--primary);
  //background-image: linear-gradient(315deg, var(--primary) 0%, var(--primary) 74%);
  //line-height: 42px;
  padding: 0;
  font-family: var(--font-family);
  border: none;
  color: var(--light-colour);
  position: relative;
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    display: inline-block;
    width: auto;
  }

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    //padding: calc(var(--borderSpacing)/3);
    padding: 30px;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    background: var(--light-colour);
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
  }

  &:before {
    height: 0%;
    width: 2px;
  }

  &:after {
    width: 0%;
    height: 2px;
  }

  &:hover:before {
    height: 100%;
  }

  &:hover:after {
    width: 100%;
  }

  &:hover {
    background: rgba(var(--primary-rgb), 0.5);
  }

  span:before,
  span:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: var(--light-colour);
    /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
    transition: all 0.3s ease;
  }

  span:before {
    width: 2px;
    height: 0%;
  }

  span:after {
    height: 2px;
    width: 0%;
  }

  span:hover:before {
    height: 100%;
  }

  span:hover:after {
    width: 100%;
  }


  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`
