import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section section--inverted">
      <div className={"container"}>
        <h2>Get in touch</h2>
        {/*<a href={'#'} className={'button'} id={'contactTrigger'}>Send us a message</a>*/}
        <form className={"contact-form"}
              name="contact"
              netlify
              method="POST"
              netlify-honeypot="bot-field"
              action={"/success/"}
              >
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor={"bot-field"} className={'visually-hidden'}>
            Don’t fill this out if you’re human: <input name="bot-field" />
          </label>
          <label htmlFor={"name"} className={"visually-hidden"}>Name</label>
          <input placeholder="Your name..." type="text" name="name" id={"name"} required />
          <label htmlFor={"email"} className={"visually-hidden"}>Email</label>
          <input placeholder="Your email..." type="email" name="email" id={"email"} required />
          <label htmlFor={"message"} className={"visually-hidden"}>Message</label>
          <textarea
            placeholder="Your message..."
            name="message"
            id="message"
            rows="5"
            required
          ></textarea>
          <Button className={"button"} text="Send Message" />
        </form>
      </div>
    </ContactStyles>
  )
}

export default Contact
